<template>
  <v-container fluid width="1500">
    <v-tabs icons-and-text centered slider-size="2" style="box-shadow: none !important" v-model="tabPossess">
      <v-tab>
        <span>Automobile</span>
        <v-icon size="20" class="me-3" color="#fff">mdi-auto</v-icon>
      </v-tab>
      <v-tab>
        <span>Immeuble</span>
        <v-icon size="20" class="me-3" color="#fff">mdi-auto</v-icon>
      </v-tab>
      <v-tab>
        <span>Meuble</span>
        <v-icon size="20" class="me-3" color="#fff">mdi-auto</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabPossess">
      <v-tab-item>
        <v-row class="justify-space-around my-2">
          <v-col cols="12" md="3">
            <v-card outlined>
              <v-card-title class="justify-center">
                <i class="fa-solid fa-car fa-5x EoleBlue--text"></i>
              </v-card-title>
              <v-card-subtitle class="text-center EoleYellow--text muller">
                <b>Voiture</b>
              </v-card-subtitle>

              <v-divider></v-divider>
              <v-card-text class="pa-3">
                <p><b>Modèle :</b> Citroen C3</p>
                <p><b>Immatriculation :</b> XXX-XX-XXX</p>
                <p><b>Collection :</b> non</p>
                <p><b>N° contrat :</b> 726883932</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card outlined>
              <v-card-title class="justify-center">
                <i class="fa-solid fa-car fa-5x EoleBlue--text"></i>
              </v-card-title>
              <v-card-subtitle class="text-center EoleYellow--text muller">
                <b>Voiture 2</b>
              </v-card-subtitle>

              <v-divider></v-divider>
              <v-card-text class="pa-3">
                <p><b>Modèle :</b> Ferrari 911</p>
                <p><b>Immatriculation :</b> XXX-XX-XXX</p>
                <p><b>Collection :</b> oui</p>
                <p><b>N° contrat :</b> 321654987</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-space-around my-2">
          <v-col cols="12" md="3">
            <v-card outlined>
              <v-card-title class="justify-center"><i class="fa-solid fa-house fa-5x EoleBlue--text"></i></v-card-title>
              <v-card-subtitle class="text-center EoleYellow--text muller">
                <b>Maison</b>
              </v-card-subtitle>

              <v-divider></v-divider>
              <v-card-text class="">
                <p><b>Adresse :</b> 26 rue des marmottes</p>
                <p><b>Status :</b> Propriétaire</p>
                <p><b>N° contrat :</b> 726883932</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-space-around my-2">
          <v-col cols="12" md="3">
            <v-card outlined>
              <v-card-title class="justify-center"
                ><i class="fa-solid fa-computer fa-5x EoleBlue--text"></i
              ></v-card-title>
              <v-card-subtitle class="text-center EoleYellow--text muller">
                <b>Ordinateur</b>
              </v-card-subtitle>

              <v-divider></v-divider>
              <v-card-text class="">
                <p><b>Prix :</b> 1000 €</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card outlined>
              <v-card-title class="justify-center"
                ><i class="fa-solid fa-computer fa-5x EoleBlue--text"></i
              ></v-card-title>
              <v-card-subtitle class="text-center EoleYellow--text muller">
                <b>Ordinateur 2</b>
              </v-card-subtitle>

              <v-divider></v-divider>
              <v-card-text>
                <p><b>Prix :</b> 2000 €</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card outlined>
              <v-card-title class="justify-center"
                ><i class="fa-solid fa-computer fa-5x EoleBlue--text"></i
              ></v-card-title>
              <v-card-subtitle class="text-center EoleYellow--text muller">
                <b>Ordinateur 3</b>
              </v-card-subtitle>

              <v-divider></v-divider>
              <v-card-text class="">
                <p><b>Prix :</b> 500 €</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  data: () => ({
    tabPossess: null,
  }),
})
</script>
<style>
.muller {
  font-family: muller;
  font-size: 1.25rem;
}
</style>
