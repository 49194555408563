<template>
  <v-container fluid class="no-padding">
    <v-row class="my-12 d-flex">
      <v-col cols="12" md="12">
        <v-timeline :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item v-for="item in items" :key="item.id" color="EoleBlueLighten">
            <v-card color="EoleYellow" class="pa-2">
              <v-card-title class="text-h6" style="color: white !important; font-size: 1rem">
              {{ item.nameHistory }}  
              </v-card-title>
              <v-card class="EoleBlue--text pa-2">
                {{ item.description }} ({{ item.dateHistory }})
              </v-card>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-col cols="12" md="12" class="text-right">
        <v-btn color="EoleGreen white--text" class="mr-5 mb-5">
          <i class="fa-solid fa-calendar-plus mr-3"></i>Ajouter un évènement
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
    defineComponent
  } from "@vue/composition-api"

export default defineComponent({
  data: () => ({
    items: [
      {
        id: 1,
        nameHistory: "1er rendez-vous",
        description: "Client arriver à 11h, présentation de agence",
        dateHistory: '12/06/2020',
      },
      {
        id: 2,
        nameHistory: "2ème rendez-vous",
        description: "Presentation des contrats assurance.",
        dateHistory: '12/06/2020',
      },
      {
        id: 3,
        nameHistory: "Signature du contrat",
        description: "qsdf",
        dateHistory: '12/06/2020',
      },
      {
        id: 4,
        nameHistory: "Déclaration sinistre",
        description: "qsdf",
        dateHistory: '12/06/2020',
      },
    ],
  }),
})
</script>