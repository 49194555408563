<template>
  <v-container fluid>
    <v-row class="justify-left pa-5">
      <v-col cols="12" md="3" v-for="(beneficialOwner, index) in listBeneficialOwner" :key="beneficialOwner.id">
        <v-card class="my-2" outlined elevation="1">
          <v-card-title class="justify-center" style="background-color: #e1e5e9">
            <v-row align="center">
              <v-col cols="12" md="10" class="text-left">
                <h5>{{ beneficialOwner.name }}</h5>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-menu left right transition="slide-x-transition" close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-dialog v-model="dialogDocument[index]" persistent max-width="950">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on:click="documentItems(index)" v-bind="attrs" v-on="on">
                              <v-icon left color="EoleBlue">mdi-file-document</v-icon>Afficher les documents
                            </div>
                          </template>
                          <v-card class="pa-5">
                            <v-card-title class="text-h6"
                              ><b>{{ beneficialOwner.name }}</b>
                            </v-card-title>
                            <v-card-text class="my-2">
                              <v-row>
                                <v-col
                                  v-for="doc in beneficialOwner.document"
                                  :key="doc.id"
                                  cols="12"
                                  md="6"
                                  class="flex d-flex flex-column"
                                >
                                  <v-card outlined class="flex d-flex flex-column">
                                    <v-card-text class="EoleYellow--text text-center">
                                      {{ beneficialOwner.document[doc.id].name }}
                                    </v-card-text>
                                    <v-card-text>
                                      <iframe
                                        :src="beneficialOwner.document[doc.id].pdfLocation"
                                        style="width: 100%; height: 300px"
                                      >
                                      </iframe>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                                <v-col class="flex d-flex flex-column">
                                  <v-card outlined link class="flex d-flex flex-column justify-center text-center">
                                    <v-card-text class="EoleBlue--text">
                                      <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                    </v-card-text>
                                    <v-card-text><small>Extension de fichier accepté : .pdf</small> </v-card-text>
                                    <v-card-text class="EoleBlue--text"> Importer document </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="#b00020" text @click="dialogDocument[index] = false"> Fermer</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider class="my-2"></v-divider>
                    <v-list-item>
                      <v-list-item-title>
                        <v-dialog v-model="dialogUpgradeBeneficialOwner[index]" persistent max-width="700">
                          <template v-slot:activator="{ on, attrs }">
                            <div @click="upgradeItems(index)" v-bind="attrs" v-on="on">
                              <v-icon left color="EoleBlue">mdi-account-arrow-up</v-icon>Passer interlocuteur
                            </div>
                          </template>
                          <v-card>
                            <v-card-title class="text-h6"
                              ><b
                                >Êtes-vous sûr de passer {{ beneficialOwner.name }} en interlocuteur ?</b
                              ></v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="green darken-1" text @click="dialogUpgradeBeneficialOwner[index] = false"
                                >Annulerqsdf
                              </v-btn>
                              <v-btn color="#52b788" text @click="upgradeBE">Accepter</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider class="my-2"></v-divider>

                    <v-list-item v-on:click="updateItems(index)" link>
                      <v-list-item-title> <v-icon left color="EoleBlue">mdi-pencil</v-icon>Modifier </v-list-item-title>
                    </v-list-item>

                    <v-divider class="my-2"></v-divider>

                    <v-list-item color="EoleError" link>
                      <v-list-item-title>
                        <v-dialog v-model="dialogDelete[index]" persistent max-width="425">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on:click="deleteItems(index)" v-bind="attrs" v-on="on">
                              <v-icon left color="error">mdi-delete</v-icon>Supprimer
                            </div>
                          </template>
                          <v-card>
                            <v-card-title class="text-h5"><b>Supprimer cette interlocuteur ? </b> </v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="green darken-1" text @click="dialogDelete[index] = false">Annuler</v-btn>
                              <v-btn color="#b00020" text @click="deleteBE">Supprimer</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>
          <v-row class="my-2" dense>
            <v-card-text><b>Adresse : </b>{{ beneficialOwner.adress || "Aucune information" }} </v-card-text>
            <v-card-text
              ><b>Ville : </b>{{ beneficialOwner.city || "Aucune information" }},
              {{ beneficialOwner.postalCode || "Aucune information" }}</v-card-text
            >
            <v-card-text
              ><b>Né(e) : </b> {{ beneficialOwner.nativeCity || "Aucune information" }} ({{
                beneficialOwner.nativeCountry
              }})</v-card-text
            >
            <v-card-text><b>Nationalité : </b>{{ beneficialOwner.nationality || "Aucune information" }}</v-card-text>
            <v-card-text
              ><b>Part entreprise : </b>{{ beneficialOwner.sharePercentage + "%" || "Aucune information" }}</v-card-text
            >
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-dialog v-model="dialogAdd" persistent max-width="825">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on:click="dialogAdd = true"
              outlined
              v-bind="attrs"
              v-on="on"
              height="100%"
              class="d-flex justify-center align-center"
            >
              <v-card-text class="pa-5">
                <v-row class="text-center">
                  <v-col cols="12" md="12">
                    <v-icon color="EoleBlue" size="64">mdi-plus-circle</v-icon>
                  </v-col>
                  <v-col cols="12" md="12">
                    <h2>Ajouter Bénéficiaire Effectif</h2>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Ajouter B./E.</span>
            </v-card-title>
            <v-card-text class="pa-5">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Nom/Prénom"
                    prepend-inner-icon="mdi-account"
                    v-model="dataBeneficialOwner.nameBE"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="adresse"
                    prepend-inner-icon="mdi-account"
                    v-model="dataBeneficialOwner.adress"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Code postal"
                    prepend-inner-icon="mdi-mailbox"
                    v-model="dataBeneficialOwner.postalCode"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Ville"
                    prepend-inner-icon="mdi-town-hall"
                    v-model="dataBeneficialOwner.city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Ville de naissance"
                    prepend-inner-icon="mdi-town-hall"
                    v-model="dataBeneficialOwner.nativeCity"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Pays de naissance"
                    prepend-inner-icon="mdi-ski-cross-country"
                    v-model="dataBeneficialOwner.nativeCountry"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Nationalité"
                    prepend-inner-icon="mdi-flag"
                    v-model="dataBeneficialOwner.nationality"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Pourcentage de part"
                    prepend-inner-icon="mdi-cash"
                    v-model="dataBeneficialOwner.sharePercentage"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialogAdd = false">Annuler</v-btn>
              <v-btn color="#52b788" text @click="AddNewBeneficialOwner">Ajouter</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="3" class="no-padding">
        <v-dialog v-model="dialogModif" persistent max-width="825">
          <v-card>
            <v-card-title>
              <span class="text-h5">Modifier B./E.</span>
            </v-card-title>
            <v-card-text class="pa-5">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Nom/Prénom"
                    prepend-inner-icon="mdi-account"
                    v-model="infoBeneficialOwner[1]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="adresse"
                    prepend-inner-icon="mdi-account"
                    v-model="infoBeneficialOwner[2]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Code postal"
                    prepend-inner-icon="mdi-mailbox"
                    v-model="infoBeneficialOwner[3]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Ville"
                    prepend-inner-icon="mdi-town-hall"
                    v-model="infoBeneficialOwner[4]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Ville de naissance"
                    prepend-inner-icon="mdi-town-hall"
                    v-model="infoBeneficialOwner[5]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Pays de naissance"
                    prepend-inner-icon="mdi-ski-cross-country"
                    v-model="infoBeneficialOwner[6]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Nationalité"
                    prepend-inner-icon="mdi-flag"
                    v-model="infoBeneficialOwner[7]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="Pourcentage de part"
                    prepend-inner-icon="mdi-cash"
                    v-model="infoBeneficialOwner[8]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeUpdateItems">Annuler</v-btn>
              <v-btn color="#52b788" text @click="updateBE">Modifier</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

export default {
  beforeMount() {
    this.fetchBeneficialOwner()
  },
  data: () => ({
    pappersInformations: [],
    dataPappers: false,

    dialogAdd: false,

    dialogModif: false,
    idUpdateBeneficialOwner: 0,
    openUpdateWindow: false,

    dialogDelete: {},
    idDeleteBE: 0,
    nameDelId: "",

    // dialogUpgrade: {},
    dialogUpgradeBeneficialOwner: {},
    idUpgradeBeneficialOwner: 0,
    infoUpgradeBeneficialOwner: [],

    dialogDocument: {},
    idDocumentBE: 0,

    dataBeneficialOwner: [],
    infoBeneficialOwner: [],
    listBeneficialOwner: [],

    loadingData: false,
    siretNotFound: false,
    siretNotFoundMessage: "",
    siret: null,
  }),

  computed: {
    directorShow() {
      return this.showDirector
    },
  },
  methods: {
    documentItems(id) {
      this.idDocumentBE = id
    },

    upgradeItems(id) {
      this.idUpgradeBeneficialOwner = id
      this.infoUpgradeBeneficialOwner.push(
        this.listBeneficialOwner[id].id,
        this.listBeneficialOwner[id].name,
        this.listBeneficialOwner[id].adress,
        this.listBeneficialOwner[id].postalCode,
        this.listBeneficialOwner[id].city,
      )
    },

    closeUpgradeItems() {
      this.dialogUpgradeBeneficialOwner[this.idUpgradeBeneficialOwner] = false
    },

    async upgradeBE() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/customer_area/customer_beneficial_owner?idBE=${this.infoUpgradeBeneficialOwner[0]}&nameBE=${this.infoUpgradeBeneficialOwner[1]}
          &adress=${this.infoUpgradeBeneficialOwner[2]}&postalCode=${this.infoUpgradeBeneficialOwner[3]}&city=${this.infoUpgradeBeneficialOwner[4]}`,
          {
            mode: "no-cors",
            method: "POST",
            headers: headers,
          },
        )
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          console.log("Envoyer ! ", response)
          this.$toast.success(`Le B./E. '${this.infoBeneficialOwner[0]}' est passé interlocuteur.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.success(`Erreur, le B./E. n'est pas passé interlocuteur.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
      this.infoUpgradeBeneficialOwner = []
      this.listBeneficialOwner = []
      this.fetchBeneficialOwner()
      // this.$emit("submittedContact")
    },

    deleteItems(id) {
      this.idDeleteBE = this.listBeneficialOwner[id].id
      this.nameDelId = this.listBeneficialOwner[id].nameBE
      console.log("text : ", this.idDeleteBE)
    },

    async deleteBE() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(`${config.apiUri}/customer_area/customer_beneficial_owner?idBE=${this.idDeleteBE}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })

        this.$toast.success(`Vous avez supprimé le B./E. ${this.nameDelId}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le B./E. ${this.nameDelId}.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
      this.listBeneficialOwner = []
      this.fetchBeneficialOwner()
    },

    AddNewBeneficialOwner() {
      let agencyId = this.$store.state.user.agency_id
      this.infoBeneficialOwner.push(
        this.dataBeneficialOwner.nameBE,
        this.dataBeneficialOwner.adress,
        this.dataBeneficialOwner.postalCode,
        this.dataBeneficialOwner.city,
        this.dataBeneficialOwner.nativeCity,
        this.dataBeneficialOwner.nativeCountry,
        this.dataBeneficialOwner.nationality,
        this.dataBeneficialOwner.sharePercentage,
        agencyId,
      )

      console.log(this.infoBeneficialOwner)

      this.addBeneficialOwner()
      this.dialogAdd = false
    },

    async addBeneficialOwner() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/customer_area/customer_beneficial_owner?nameBE=${this.infoBeneficialOwner[0]}&adress=${this.infoBeneficialOwner[1]}&postalCode=${this.infoBeneficialOwner[2]}
          &city=${this.infoBeneficialOwner[3]}&bornCity=${this.infoBeneficialOwner[4]}&countryBorn=${this.infoBeneficialOwner[5]}
          &nationality=${this.infoBeneficialOwner[6]}&sharePercentage=${this.infoBeneficialOwner[7]}&agencyId=${this.infoBeneficialOwner[8]}`,
          {
            mode: "no-cors",
            method: "POST",
            headers: headers,
          },
        )
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          console.log("Envoyer ! ", response)
          this.$toast.success(`Le B./E. '${this.infoBeneficialOwner[0]}' est bien ajoutés.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.success(`Erreur d'envoie, le B./E. n'a pas été ajouté.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
      this.dataBeneficialOwner = []
      this.infoBeneficialOwner = []
      this.listBeneficialOwner = []
      this.fetchBeneficialOwner()
    },

    updateItems(id) {
      this.idUpdateBeneficialOwner = id
      console.log(id)

      this.infoBeneficialOwner.push(
        this.listBeneficialOwner[id].id,
        this.listBeneficialOwner[id].name,
        this.listBeneficialOwner[id].adress,
        this.listBeneficialOwner[id].postalCode,
        this.listBeneficialOwner[id].city,
        this.listBeneficialOwner[id].nativeCity,
        this.listBeneficialOwner[id].nativeCountry,
        this.listBeneficialOwner[id].nationality,
        this.listBeneficialOwner[id].sharePercentage,
      )

      this.openUpdateWindow = true
      this.dialogModif = true
    },

    closeUpdateItems() {
      this.openUpdateWindow = false
      this.dialogModif = false

      this.infoBeneficialOwner = []
    },

    async updateBE() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/customer_area/customer_beneficial_owner?&idBE=${this.infoBeneficialOwner[0]}&nameBE=${this.infoBeneficialOwner[1]}&adress=${this.infoBeneficialOwner[2]}&postalCode=${this.infoBeneficialOwner[3]}
          &city=${this.infoBeneficialOwner[4]}&bornCity=${this.infoBeneficialOwner[5]}&countryBorn=${this.infoBeneficialOwner[6]}
          &nationality=${this.infoBeneficialOwner[7]}&sharePercentage=${this.infoBeneficialOwner[8]}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          console.log("Envoyer ! ", response)
          this.$toast.success(`Le B./E. '${this.infoBeneficialOwner[1]}' est bien modifé.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        this.$toast.success(`Erreur dans la modification. '${this.infoBeneficialOwner[1]}' n'a pas été modifé.`, {
          position: "bottom-right",
          timeout: 5000,
        })

        console.log(e)
      }
      this.dialogModif = false
      this.openUpdateWindow = false
      this.infoBeneficialOwner = []
      this.listBeneficialOwner = []
      this.fetchBeneficialOwner()
    },

    async fetchBeneficialOwner() {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/customer_area/customer_beneficial_owner`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.listBeneficialOwner.push({
            id: element.id,
            id_interloc: element.id_interlocutor,
            name: element.name_beneficial_owner,
            adress: element.adress,
            postalCode: element.postal_code,
            city: element.city,
            nativeCity: element.born_city,
            nativeCountry: element.country_born,
            nationality: element.nationality,
            sharePercentage: element.share_percentage,
            document: element.document,
          })
        })

        this.$toast.info(`Affichage des Bénéficiaires Effectifs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de charger les éléments Bénéficiaires Effectifs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
        console.log(e)
      }
    },
  },
}
</script>
<style>
.no-padding {
  padding: 0px !important;
}
</style>
