<template>
  <v-stepper v-model="e1" flat style="background-color: transparent">
    <v-stepper-items>
      <v-stepper-content :step="1">
        <customer-choose-first-page
          @addNewCustomer="addNewCustomer"
          @showPageCustomer="nameOfCustomer"
        ></customer-choose-first-page>
      </v-stepper-content>
    </v-stepper-items>

    <v-stepper-items>
      <v-stepper-content :step="2" class="no-padding">
        <v-row class="mt-10 px-5">
          <h2 class="mullerFont" style="font-size: 1.5rem">MON ESPACE CLIENT</h2>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <customer-select-type @chooseCusto="chooseCusto" @nextStep4="nextStep" @lastStep2="lastStep">
        </customer-select-type>
      </v-stepper-content>
    </v-stepper-items>

    <v-stepper-items v-if="this.chooseCustomer === 'professionnel'">
      <v-stepper-content :step="3" class="no-padding">
        <v-row class="mt-10 px-5">
          <h2 class="mullerFont" style="font-size: 1.5rem">MON ESPACE CLIENT</h2>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <customer-create-pro @lastStepProPart="lastStep" @nextStepHome="nextStep"></customer-create-pro>
      </v-stepper-content>
    </v-stepper-items>

    <v-stepper-items v-else>
      <v-stepper-content :step="3" class="no-padding">
        <v-row class="mt-10 px-5">
          <h2 class="mullerFont" style="font-size: 1.5rem">MON ESPACE CLIENT</h2>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <customer-create-part @lastStepProPart="lastStep" @nextStepHome="nextStep"> </customer-create-part>
      </v-stepper-content>
    </v-stepper-items>

    <v-stepper-items>
      <v-stepper-content :step="4">
        <v-btn class="mr-5 mb-15" color="transparent" elevation="0" @click="reload()">
          <v-icon dark left> mdi-arrow-left </v-icon>Précédent
        </v-btn>
        <v-card width="1500" outlined elevation="3">
          <customer-header
            v-if="customerId != 0"
            v-bind:customerId="customerId"
            v-bind:customerTypology="customerTypology"
          ></customer-header>
        </v-card>
        <br />
        <v-tabs
          class="mt-5"
          color="EoleYellow"
          v-model="tab"
          icons-and-text
          centered
          slider-size="2"
          style="box-shadow: none !important"
          background-color="transparent"
        >
          <v-row class="justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-house fa-2x"></i>
                </v-tab>
              </template>
              <span>Vue 360</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-file-signature fa-2x"></i>
                </v-tab>
              </template>
              <span>Contrats</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-car-burst fa-2x"></i>
                </v-tab>
              </template>
              <span>Sinistres</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-list-check fa-2x"></i>
                </v-tab>
              </template>
              <span>Tâches</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-comments fa-2x"></i>
                </v-tab>
              </template>
              <span>Echanges</span>
            </v-tooltip>
            <v-tooltip top v-if="customerFranchise === 1">
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-building-flag fa-2x"></i>
                </v-tab>
              </template>
              <span>Franchise</span>
            </v-tooltip>
            <v-tooltip top v-if="this.customerTypology === 'professionnel'">
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-city fa-2x"></i>
                </v-tab>
              </template>
              <span>Etablissement</span>
            </v-tooltip>
            <v-tooltip top v-if="this.customerTypology === 'professionnel'">
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-user-tie fa-2x"></i>
                </v-tab>
              </template>
              <span>Interlocuteur</span>
            </v-tooltip>
            <v-tooltip top v-if="this.customerTypology === 'particulier'">
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-hand-holding-heart fa-2x"></i>
                </v-tab>
              </template>
              <span>Biens</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-folder-open fa-2x"></i>
                </v-tab>
              </template>
              <span>Documents</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-timeline fa-2x"></i>
                </v-tab>
              </template>
              <span>Historique</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-clipboard-list fa-2x"></i>
                </v-tab>
              </template>
              <span>Formulaire</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-gavel fa-2x"></i>
                </v-tab>
              </template>
              <span>Lettre de devoir de conseil</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  <i class="fa-solid fa-warning fa-2x"></i>
                </v-tab>
              </template>
              <span>Réclamaion client</span>
            </v-tooltip>
          </v-row>
        </v-tabs>
        <v-divider></v-divider>

        <v-tabs-items v-model="tab" class="custom-tab-items">
          <v-tab-item v-if="customerId != 0">
            <customer-home v-bind:customerId="customerId" v-bind:customerTypology="customerTypology"></customer-home>
          </v-tab-item>

          <v-tab-item>
            <customer-contract></customer-contract>
          </v-tab-item>
          <v-tab-item>
            <customer-sinister></customer-sinister>
          </v-tab-item>
          <v-tab-item>
            <customer-task></customer-task>
          </v-tab-item>
          <v-tab-item>
            <customer-exchange></customer-exchange>
          </v-tab-item>
          <v-tab-item v-if="customerFranchise === 1">
            <customer-franchise v-bind:customerId="customerId"></customer-franchise>
          </v-tab-item>
          <v-tab-item v-if="customerTypology === 'professionnel'">
            <customer-institution></customer-institution>
          </v-tab-item>
          <v-tab-item v-if="customerTypology === 'professionnel'">
            <customer-interlocutor v-bind:customerId="customerId"></customer-interlocutor>
          </v-tab-item>
          <v-tab-item>
            <customer-document v-bind:customerId="customerId"></customer-document>
          </v-tab-item>
          <v-tab-item>
            <customer-history></customer-history>
          </v-tab-item>
          <v-tab-item v-if="customerTypology === 'particulier'">
            <customer-bien-particulier></customer-bien-particulier>
          </v-tab-item>
          <v-tab-item> Form </v-tab-item>
          <v-tab-item> LDC </v-tab-item>
          <v-tab-item> Récla </v-tab-item>
        </v-tabs-items>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css" // Load icons
import CustomerHome from "./components/CustomerHome.vue"
import CustomerInterlocutor from "./components/CustomerInterlocutor.vue"
import CustomerHeader from "./components/CustomerHeader.vue"
import CustomerBeneficialOwner from "./components/CustomerBeneficialOwner.vue"
import CustomerContract from "./components/CustomerContract.vue"
import CustomerExchange from "./components/CustomerExchange.vue"
import CustomerInstitution from "./components/CustomerInstitution.vue"
import CustomerTask from "./components/CustomerTask.vue"
import CustomerSinister from "./components/CustomerSinister.vue"
import CustomerChooseFirstPage from "./components/CustomerAction.vue"
import CustomerCreatePro from "./components/CustomerAddProfessionnal.vue"
import CustomerSelectType from "./components/CustomerSelectType"
import CustomerCreatePart from "./components/CustomerAddParticular.vue"
import CustomerDocument from "./components/CustomerDocument.vue"
import CustomerBienParticulier from "./components/CustomerParticularProperty.vue"
import CustomerHistory from "./components/CustomerHistory.vue"
import CustomerFranchise from "./components/CustomerFranchise.vue"

export default {
  data: () => ({
    show: false,
    e1: 1,
    typology: "",
    addCustomer: 0,
    chooseCustomer: "",
    customerId: 0,
    customerTypology: "",
    customerFranchise: null,
    tab: null,
  }),

  components: {
    CustomerHome,
    CustomerInterlocutor,
    CustomerHeader,
    CustomerBeneficialOwner,
    CustomerContract,
    CustomerExchange,
    CustomerInstitution,
    CustomerTask,
    CustomerSinister,
    CustomerChooseFirstPage,
    CustomerCreatePro,
    CustomerSelectType,
    CustomerCreatePart,
    CustomerDocument,
    CustomerBienParticulier,
    CustomerHistory,
    CustomerFranchise,
  },

  methods: {
    nameOfCustomer(idCustomer, typoCustomer, franchise) {
      // console.log(" ", idCustomer, typoCustomer, franchise)
      this.customerId = idCustomer
      this.customerTypology = typoCustomer
      this.customerFranchise = parseInt(franchise)
      this.nextStep(3)
    },

    addNewCustomer() {
      this.nextStep(1)
    },

    chooseCusto(client) {
      this.chooseCustomer = client
    },

    nextStep(n) {
      this.e1 = n + 1
    },
    lastStep(n) {
      this.e1 = n - 1
    },

    reload() {
      window.location.reload()
    },
  },
}
</script>
<style>
.custom-tab-items {
  background-color: transparent !important;
}

.mullerFont {
  font-family: muller;
  font-size: 1rem;
  filter: contrast(0.3);
}

.backTabs {
  /* box-shadow: none !important; */
  background-color: transparent !important;
}
</style>
