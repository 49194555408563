<template>
  <v-container fluid width="1500">
    <v-row class="pa-5">
      <v-col cols="12" md="6" class="text-left pa-5">
        <v-btn class="mr-5" color="transparent" elevation="0" @click="lastStep">
          <v-icon dark left> mdi-arrow-left </v-icon>Précédent
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" md="12">
        <v-card-title class="justify-center" style="color: #fcc03c !important">Choix de la typologie de votre client</v-card-title>
        <v-card-subtitle class="text-center">Pour commencer, veuillez choisir la typologie de votre client. </v-card-subtitle>
      </v-col>
      <v-col cols="12" md="12">
        <v-row class="justify-center">
          <v-card v-on:click="chooseCusto('professionnel')" flat outlined width="350" height="350" class="align-center d-flex mr-5">
            <v-card-text class="text-center pa-5">
              <i class="fa-solid fa-building fa-8x EoleBlue--text"></i>
              <p class="mt-10 text-h6 EoleBlue--text">PROFESSIONNEL</p>
            </v-card-text>
          </v-card>
          <v-card v-on:click="chooseCusto('particulier')" flat outlined width="350" height="350" class="align-center d-flex">
            <v-card-text class="text-center">
              <i class="fa-solid fa-people-arrows fa-8x EoleBlue--text"></i>
              <p class="mt-10 text-h6 EoleBlue--text">PARTICULIER</p>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  setup() {},

  methods: {
    chooseCusto(custo) {
      this.$emit("chooseCusto", custo)
      this.$emit("nextStep4", 2)
    },

    lastStep() {
      this.$emit("lastStep2", 2)
    },
  },
})
</script>
