<template>
  <v-container fluid class="no-padding">
    <v-tabs v-model="tabChooseLeader" class="mt-12" style="box-shadow: none !important" background-color="transparent">
      <v-tab>INTERLOCUTEURS</v-tab>
      <v-tab>DIRIGEANTS</v-tab>
      <v-tab>BENEFICIAIRE EFFECTIFS</v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="tabChooseLeader" class="custom-tab-items">
      <v-tab-item>
        <v-row class="my-12 d-flex">
          <v-col cols="12" md="3" v-for="(director, index) in listDirector" :key="director.id">
            <v-card outlined>
              <v-card-title>
                <v-row>
                  <v-col cols="12" md="10"></v-col>
                  <v-col cols="12" md="2" class="text-right">
                    <v-menu right transition="slide-x-transition" close-on-content-click>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <v-dialog v-model="dialogUpgrade[index]" persistent max-width="700">
                              <template v-slot:activator="{ on, attrs }">
                                <div @click="upgradeItems(index)" v-bind="attrs" v-on="on">
                                  <v-icon left color="EoleBlue">mdi-account-arrow-up</v-icon>Passer interlocuteur
                                </div>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Supprimer cette interlocuteur ?</span>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pa-5">
                                  Êtes-vous sûr de passer <b>{{ director.nameInterlocutor }}</b> en interlocuteur ?
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="green darken-1" text @click="dialogUpgrade[index] = false"
                                    >Annuler</v-btn
                                  >
                                  <v-btn color="#52b788" text @click="upgradeInterloc">Accepter</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-list-item-title>
                        </v-list-item>

                        <v-divider class="my-2"></v-divider>

                        <v-list-item v-on:click="updateItems(index)" link>
                          <v-list-item-title>
                            <v-icon left color="EoleBlue">mdi-pencil</v-icon>Modifier
                          </v-list-item-title>
                        </v-list-item>

                        <v-divider class="my-2"></v-divider>

                        <v-list-item color="EoleError" link>
                          <v-list-item-title>
                            <v-dialog v-model="dialogDelete[index]" persistent max-width="425">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on:click="deleteItems(index)" v-bind="attrs" v-on="on">
                                  <v-icon left color="error">mdi-delete</v-icon>Supprimer
                                </div>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Supprimer cette interlocuteur ?</span>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pa-5">
                                  Etes-vous sûr de vouloir supprimer <b>{{ director.nameInterlocutor }}</b> ?
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="green darken-1" text @click="dialogDelete[index] = false"
                                    >Annuler</v-btn
                                  >
                                  <v-btn color="#b00020" text @click="delInterloc">Supprimer</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card flat class="flex d-flex flex-column">
                <v-card-text class="text-center">
                  <v-icon size="64">mdi-human-male</v-icon>
                </v-card-text>

                <v-card-text class="EoleGreen--text text-h6 text-center">
                  {{ director.nameInterlocutor }}
                  <v-icon color="EoleYellow" size="16" v-if="director.mainInterlocutor === 1">mdi-star</v-icon>
                </v-card-text>

                <v-card-text style="margin-bottom: -10px">
                  <v-row class="my-1">
                    <v-card-text><strong>Qualité : </strong>{{ director.quality || "A compléter" }} </v-card-text>
                    <v-card-text><b>Numéro : </b>{{ director.phone || "A compléter" }}</v-card-text>
                    <v-card-text><b>Mail : </b>{{ director.email || "A compléter" }}</v-card-text>
                    <v-card-text>
                      <strong>Domicilié au : </strong>
                      {{ director.address || "Aucune information" }}
                      <br />{{ director.city }}, {{ director.postalCode }}
                    </v-card-text>
                  </v-row>
                </v-card-text>
                <v-card-text class="text-center">
                  <h3>Bénéficiaire Effectif</h3>
                </v-card-text>

                <v-card-text class="my-2">
                  <v-row>
                    <v-card-text
                      ><b>Adresse : </b>{{ director.beneficialOwner[0].address || "Aucune information" }}
                    </v-card-text>
                    <v-card-text
                      ><b>Ville : </b>{{ director.beneficialOwner[0].city || "Aucune information" }},
                      {{ director.beneficialOwner[0].postal_code || "Aucune information" }}</v-card-text
                    >
                    <v-card-text
                      ><b>Né(e) : </b> {{ director.beneficialOwner[0].born_city || "Aucune information" }} ({{
                        director.beneficialOwner[0].country_born
                      }})</v-card-text
                    >
                    <v-card-text
                      ><b>Nationalité : </b>{{ director.beneficialOwner[0].nationality || "Aucune information" }}
                    </v-card-text>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-dialog v-model="dialogAdd" persistent max-width="825">
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  v-on:click="dialogAdd = true"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  height="100%"
                  class="d-flex align-center"
                >
                  <v-card-text class="pa-5">
                    <v-row class="text-center">
                      <v-col cols="12" md="12">
                        <v-icon color="EoleBlue" size="64">mdi-plus-circle</v-icon>
                      </v-col>
                      <v-col cols="12" md="12">
                        <h2>Ajouter Interlocuteur</h2>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
              <v-card>
                <v-card-title><span class="text-h5 EoleBlue--text">Ajouter Interlocuteur</span></v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-5">
                  <v-card-text class="text-h6">Information Interlocuteur</v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Nom/Prénom"
                        prepend-inner-icon="mdi-account"
                        v-model="dataInterlocutor.nameInterlocutor"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Qualité"
                        prepend-inner-icon="mdi-account"
                        v-model="dataInterlocutor.quality"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Numéro"
                        prepend-inner-icon="mdi-phone"
                        v-model="dataInterlocutor.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Mail"
                        prepend-inner-icon="mdi-mail"
                        v-model="dataInterlocutor.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        outlined
                        label="Adresse complète"
                        prepend-inner-icon="mdi-map-marker"
                        v-model="dataInterlocutor.address"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Ville"
                        prepend-inner-icon="mdi-town-hall"
                        v-model="dataInterlocutor.city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        outlined
                        label="Code postal"
                        prepend-inner-icon="mdi-mailbox"
                        v-model="dataInterlocutor.postalCode"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-text class="text-h6">Information Bénéficiaire Effectif</v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Nom/Prénom du B./E."
                        prepend-inner-icon="mdi-account"
                        v-model="dataInterlocutor.nameBeneficialOwner"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Nationalité du B./E."
                        prepend-inner-icon="mdi-flag"
                        v-model="dataInterlocutor.nationalityBeneficialOwner"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        outlined
                        label="Adresse du B./E."
                        prepend-inner-icon="mdi-map-marker"
                        v-model="dataInterlocutor.addressBeneficialOwner"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Ville du B./E."
                        prepend-inner-icon="mdi-town-hall"
                        v-model="dataInterlocutor.cityBeneficialOwner"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        outlined
                        label="Code postal du B./E."
                        prepend-inner-icon="mdi-mailbox"
                        v-model="dataInterlocutor.postalCodeBeneficialOwner"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Ville de naissance du B./E."
                        prepend-inner-icon="mdi-town-hall"
                        v-model="dataInterlocutor.bornCityBeneficialOwner"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Pays de naissance du B./E."
                        prepend-inner-icon="mdi-map"
                        v-model="dataInterlocutor.bornCountryBeneficialOwner"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialogAdd = false">Annuler</v-btn>
                  <v-btn color="#52b788" text @click="addItems">Ajouter</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" md="3" class="no-padding">
            <v-dialog v-model="dialogModif" persistent max-width="825">
              <v-card>
                <v-card-title><span class="text-h5 EoleBlue--text">Modifier Interlocuteur</span></v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Nom/Prénom"
                        prepend-inner-icon="mdi-account"
                        v-model="infoInterlocutor[1]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Qualité"
                        prepend-inner-icon="mdi-account"
                        v-model="infoInterlocutor[2]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Numéro"
                        prepend-inner-icon="mdi-phone"
                        v-model="infoInterlocutor[3]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Mail"
                        prepend-inner-icon="mdi-mail"
                        v-model="infoInterlocutor[4]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        outlined
                        label="Adresse complète"
                        prepend-inner-icon="mdi-map-marker"
                        v-model="infoInterlocutor[5]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Ville"
                        prepend-inner-icon="mdi-town-hall"
                        v-model="infoInterlocutor[6]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        outlined
                        label="Code postal"
                        prepend-inner-icon="mdi-mailbox"
                        v-model="infoInterlocutor[7]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-text class="justify-center text-h6">Information Bénéficiaire Effectif</v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Nom/Prénom du B./E."
                        prepend-inner-icon="mdi-account"
                        v-model="infoInterlocutor[8]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Nationalité du B./E."
                        prepend-inner-icon="mdi-flag"
                        v-model="infoInterlocutor[14]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        outlined
                        label="Adresse du B./E."
                        prepend-inner-icon="mdi-map-marker"
                        v-model="infoInterlocutor[9]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Ville du B./E."
                        prepend-inner-icon="mdi-town-hall"
                        v-model="infoInterlocutor[11]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        outlined
                        label="Code postal du B./E."
                        prepend-inner-icon="mdi-mailbox"
                        v-model="infoInterlocutor[10]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Ville de naissance du B./E."
                        prepend-inner-icon="mdi-town-hall"
                        v-model="infoInterlocutor[12]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Pays de naissance du B./E."
                        prepend-inner-icon="mdi-map"
                        v-model="infoInterlocutor[13]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="closeUpdateItems">Annuler</v-btn>
                  <v-btn color="#52b788" text @click="updateInterloc">Modifier</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="my-12 d-flex">
          <v-col cols="12" md="3" v-for="leader in listLeader" :key="leader.id">
            <v-card outlined class="flex d-flex flex-column">
              <v-card-text class="text-center">
                <v-icon size="64">mdi-human-male</v-icon>
              </v-card-text>

              <v-card-text class="EoleGreen--text text-h6 text-center">
                {{ leader.firstname }} {{ leader.lastname }}
              </v-card-text>

              <v-card-text style="margin-bottom: -10px">
                <v-row class="my-1">
                  <v-card-text><strong>Qualité : </strong>{{ leader.quality || "A compléter" }} </v-card-text>
                  <v-card-text>
                    <strong>Domicilié au : </strong>
                    {{ leader.address || "Aucune information" }}
                    <br />{{ leader.city }}, {{ leader.postalCode }}
                  </v-card-text>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>Bénéficiaire Effectif</v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

export default {
  beforeMount() {
    this.fetchInterlocutor()
  },

  props: ["customerId"],

  mounted() {
    this.$watch("customerId", this.fetchInterlocutor)
  },

  data: () => ({
    tabChooseLeader: null,

    pappersInformations: [],
    dataPappers: false,

    dialogAdd: false,

    dialogModif: false,
    idModifyInterloc: 0,
    openUpdateWindow: false,

    dialogDelete: {},
    idDeleteInterloc: 0,

    dialogUpgrade: {},
    idUpgradeInterloc: 0,

    dataInterlocutor: [],
    infoInterlocutor: [],
    listDirector: [],
    listBeneficialOwner: [],
    listLeader: [],
  }),

  methods: {
    upgradeItems(index) {
      this.idUpgradeInterloc = index
      console.log(this.listDirector[this.idUpgradeInterloc].id)
    },

    async upgradeInterloc() {
      let agencyId = this.$store.state.user.agency_id
      console.log(this.idUpgradeInterloc)
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional/customer_interlocutor?idAgency=${agencyId}
          &idCustomer=${this.customerId}&idUpgradeInterloc=${this.listDirector[this.idUpgradeInterloc].id}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          console.log("Envoyer ! ", response)
          this.$toast.success(
            `${this.listDirector[this.idUpgradeInterloc].nameInterlocutor} est passé interlocuteur principal.`,
            {
              position: "bottom-right",
              timeout: 5000,
            },
          )
        }
      } catch (e) {
        console.log(e)
      }
      this.infoInterlocutor = []
      this.listDirector = []
      this.fetchInterlocutor()
      this.dialogUpgrade[this.idUpgradeInterloc] = false
    },

    insertFile(idInter, documentId) {
      console.log("test ", idInter, documentId, this.listDirector[idInter].document[documentId].loading)
      this.listDirector[idInter].document[documentId].loading = true
      setTimeout(() => {
        this.listDirector[idInter].document[documentId].loading = false
        this.listDirector[idInter].document[documentId].available =
          !this.listDirector[idInter].document[documentId].available
      }, 3000)
    },

    deleteItems(id) {
      this.idDeleteInterloc = this.listDirector[id].id
      this.nameDelId = this.listDirector[id].nameInterlocutor
      console.log("id delete : ", this.idDeleteInterloc)
    },

    async delInterloc() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        console.log("Del Interloc -> ", agencyId, this.customerId, this.idDeleteInterloc)

        let response = await fetch(
          `${config.apiUri}/agencies/customer_professional/customer_interlocutor?idAgency=${agencyId}&idCustomer=${this.customerId}&idInterloc=${this.idDeleteInterloc}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          },
        )

        this.$toast.success(`Vous avez supprimé l'interlocuteur : ${this.nameDelId}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer l'interlocuteur : ${this.nameDelId}.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
      this.listDirector = []
      this.fetchInterlocutor()
    },

    addItems() {
      // let agencyId = this.$store.state.user.agency_id
      this.infoInterlocutor.push(
        this.dataInterlocutor.nameInterlocutor,
        this.dataInterlocutor.quality,
        this.dataInterlocutor.phone,
        this.dataInterlocutor.email,
        this.dataInterlocutor.address,
        this.dataInterlocutor.city,
        this.dataInterlocutor.postalCode,
        this.dataInterlocutor.nameBeneficialOwner,
        this.dataInterlocutor.addressBeneficialOwner,
        this.dataInterlocutor.postalCodeBeneficialOwner,
        this.dataInterlocutor.cityBeneficialOwner,
        this.dataInterlocutor.bornCityBeneficialOwner,
        this.dataInterlocutor.bornCountryBeneficialOwner,
        this.dataInterlocutor.nationalityBeneficialOwner,
      )

      this.addInterloc()
      this.dialogAdd = false
    },

    async addInterloc() {
      let agencyId = this.$store.state.user.agency_id

      console.log("info : ", this.customerId, this.infoInterlocutor)

      try {
        console.log("test")
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        console.log(this.infoInterlocutor)

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional/customer_interlocutor?idAgency=${agencyId}&idCustomer=${this.customerId}&nameInterloc=${this.infoInterlocutor[0]}&quality=${this.infoInterlocutor[1]}&phone=${this.infoInterlocutor[2]}
          &email=${this.infoInterlocutor[3]}&address=${this.infoInterlocutor[4]}&city=${this.infoInterlocutor[5]}&postalCode=${this.infoInterlocutor[6]}&nameBeneficialOwner=${this.infoInterlocutor[7]}
          &addressBeneficialOwner=${this.infoInterlocutor[8]}&postalCodeBeneficialOwner=${this.infoInterlocutor[9]}
          &cityBeneficialOwner=${this.infoInterlocutor[10]}&bornCityBeneficialOwner=${this.infoInterlocutor[11]}&bornCountryBeneficialOwner=${this.infoInterlocutor[12]}
          &nationalityBeneficialOwner=${this.infoInterlocutor[13]}`,
          {
            mode: "no-cors",
            method: "POST",
            headers: headers,
          },
        )
        console.log(response)
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          console.log("Envoyer ! ", response)
          this.$toast.success(`Le B./E. '${this.infoInterlocutor[0]}' est bien ajoutés.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Erreur lors de l'envoie.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
      this.dataInterlocutor = []
      this.infoInterlocutor = []
      this.listDirector = []
      this.fetchInterlocutor()
    },

    updateItems(id) {
      this.idUpdateInterloc = id
      console.log("ok", this.listDirector[id].beneficialOwner[0].address)

      this.infoInterlocutor.push(
        this.listDirector[id].id,
        this.listDirector[id].nameInterlocutor,
        this.listDirector[id].quality,
        this.listDirector[id].phone,
        this.listDirector[id].email,
        this.listDirector[id].address,
        this.listDirector[id].city,
        this.listDirector[id].postalCode,
        this.listDirector[id].beneficialOwner[0].name,
        this.listDirector[id].beneficialOwner[0].address,
        this.listDirector[id].beneficialOwner[0].postal_code,
        this.listDirector[id].beneficialOwner[0].city,
        this.listDirector[id].beneficialOwner[0].born_city,
        this.listDirector[id].beneficialOwner[0].country_born,
        this.listDirector[id].beneficialOwner[0].nationality,
      )

      console.log(this.infoInterlocutor)

      this.openUpdateWindow = true
      this.dialogModif = true
    },

    closeUpdateItems() {
      this.openUpdateWindow = false
      this.dialogModif = false

      this.infoInterlocutor = []
    },

    async updateInterloc() {
      let agencyId = this.$store.state.user.agency_id

      console.log("Agence : ", agencyId, " idCustomer : ", this.customerId, " all -> ", this.infoInterlocutor[0])
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional/customer_interlocutor?idAgency=${agencyId}
          &idCustomer=${this.customerId}&idInterloc=${this.infoInterlocutor[0]}&nameInterloc=${this.infoInterlocutor[1]}&quality=${this.infoInterlocutor[2]}
          &phone=${this.infoInterlocutor[3]}&email=${this.infoInterlocutor[4]}&address=${this.infoInterlocutor[5]}&city=${this.infoInterlocutor[6]}&postalCode=${this.infoInterlocutor[7]}
          &addressBe=${this.infoInterlocutor[8]}&postalCodeBe=${this.infoInterlocutor[9]}&cityBe=${this.infoInterlocutor[10]}
          &bornCityBe=${this.infoInterlocutor[11]}&countryBornBe=${this.infoInterlocutor[12]}&nationality=${this.infoInterlocutor[13]}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )
        if (response.status === 400) {
          console.log("Eurreur dans l'envoie !")
        } else {
          console.log("Envoyer ! ", response)
          this.$toast.success(`Le B./E. '${this.infoInterlocutor[1]}' est bien modifé.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        console.log(e)
      }
      this.dialogModif = false
      this.openUpdateWindow = false
      this.infoInterlocutor = []
      this.listDirector = []
      this.fetchInterlocutor()
    },

    async fetchInterlocutor() {
      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}/customer_interlocutor`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()

        this.listDirector = []
        data.Interlocutor.forEach(element => {
          if (element.beneficial_owner[0] != undefined) {
            this.listDirector.push({
              id: element.id,
              mainInterlocutor: element.main_interlocutor,
              nameInterlocutor: element.name,
              quality: element.quality,
              phone: element.phone,
              email: element.email,
              address: element.address,
              city: element.city,
              postalCode: element.postal_code,
              beneficialOwner: element.beneficial_owner,
            })
          } else {
            this.listDirector.push({
              id: element.id,
              nameInterlocutor: element.name,
              quality: element.quality,
              phone: element.phone,
              email: element.email,
              address: element.address,
              city: element.city,
              postalCode: element.postal_code,
              beneficialOwner: "",
            })
          }
        })

        this.$toast.info(`Affichage des Interlocuteurs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      } catch (error) {
        this.$toast.error(`Impossible de charger les éléments interlocuteurs.`, {
          position: "bottom-right",
          timeout: 5000,
        })
        console.log(error)
      }

      this.fetchCustomerLeader()
    },

    async fetchCustomerLeader() {
      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/${agencyId}/customer_professional/${this.customerId}/customer_professional_leader`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )
        const data = await response.json()

        this.listLeader = []
        data.leaders.forEach(element => {
          this.listLeader.push({
            id: element.id,
            firstname: element.firstname,
            lastname: element.lastname,
            quality: element.quality,
            gender: element.gender,
            address: element.address,
            postalCode: element.postal_code,
            city: element.city,
            city_born: element.city_born,
            country: element.country,
            country_born: element.country_born,
          })
        })
      } catch (error) {
        this.$toast.error(`Impossible de charger les éléments Dirigeants.`, {
          position: "bottom-right",
          timeout: 5000,
        })
        console.log(error)
      }
      console.log("leaders : ", this.listLeader)
    },
  },
}
</script>
<style>
.no-padding {
  padding: 0px !important;
}

.custom-tab-items {
  background-color: transparent !important;
}
</style>
